import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { WordpressService } from '../../services/wordpress.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-property',
  templateUrl: './property.page.html',
  styleUrls: ['./property.page.scss'],
})
export class PropertyPage implements OnInit {

  public action: string = 'Create';
  public propertyForm: FormGroup;
  public property_id: number;
  public property_name: string;
  public address_line_one: string;
  public address_line_two: string;
  public town_city: string;
  public county: string;
  public postcode: string;
  public confirm: boolean = false;
  public brandColour: string = '#5f88a2';

  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    public navParams: NavParams,
    public formBuilder: FormBuilder,
    private storage: Storage,
    public alertController: AlertController,
    private wordpressService: WordpressService,
  ) { 
    this.property_id = this.navParams.get('property_id');

    if ( this.property_id !== 0 ) {
      this.action = 'Update';
      this.readProperty();
    }
  }

  ngOnInit() {
    this.storage.get( 'user_info' )
    .then( ( user_info: any ) => {
      if ( user_info.colour.length > 0 ) {
        this.brandColour = user_info.colour;
      }
    } );
    this.formGroup();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  formGroup() {
    this.propertyForm = this.formBuilder.group( {
      property_name: ['', Validators.required],
      address_line_one: ['', Validators.required],
      address_line_two: [''],
      town_city: ['', Validators.required],
      county: ['', Validators.required],
      postcode: ['', Validators.required],
      confirm: [false, Validators.requiredTrue],
      //bed_count: ['', Validators.compose([Validators.required, Validators.pattern(/^[0-9]\d*$/)])],
    } );
  }

  createProperty(): void {

    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      let data = {
        'property_name': this.propertyForm.value.property_name,
        'address_line_one': this.propertyForm.value.address_line_one,
        'address_line_two': this.propertyForm.value.address_line_two,
        'town_city': this.propertyForm.value.town_city,
        'county': this.propertyForm.value.county,
        'postcode': this.propertyForm.value.postcode,
        'confirm': this.propertyForm.value.confirm,
      };

      this.wordpressService.createProperty( user_info.token, data ).then( ( response: any ) => {
        if ( response.success ) {
          this.closeModal();
        } else {
          this.presentToast( response.data.message, false );
        }
      } );

    } );
  }

  readProperty(): void {

    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      this.wordpressService.readProperty( user_info.token, this.property_id ).then( ( response: any ) => {
        this.property_name = response.data.property_name;
        this.address_line_one = response.data.address_line_one;
        this.address_line_two = response.data.address_line_two;
        this.town_city = response.data.town_city;
        this.county = response.data.county;
        this.postcode = response.data.postcode;

        if ( ! response.success ) {
          this.presentToast( response.data.message, false );
        }

      } );

    } );
  }

  updateProperty(): void {
    
    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      let data = {
        'property_id': this.property_id,
        'property_name': this.propertyForm.value.property_name,
        'address_line_one': this.propertyForm.value.address_line_one,
        'address_line_two': this.propertyForm.value.address_line_two,
        'town_city': this.propertyForm.value.town_city,
        'county': this.propertyForm.value.county,
        'postcode': this.propertyForm.value.postcode,
      };

      this.wordpressService.updateProperty( user_info.token, data ).then( ( response: any ) => {
        let success = response.success ? true : false;
        this.presentToast( response.data.message, success );
      } );

    } );

  }

  async presentToast( message: string, success: boolean ) {

    let color = success ? 'success' : 'danger';
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
      cssClass: 'toast-message'
    });
    toast.present();
  }

}
