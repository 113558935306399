import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { WordpressService } from '../../services/wordpress.service';
import { comparePasswordValidator } from  '../../validators/compare-passwords';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-client',
  templateUrl: './client.page.html',
  styleUrls: ['./client.page.scss'],
})
export class ClientPage implements OnInit {

  public token: string;
  public action: string = 'Create';
  public clientForm: FormGroup;
  public client_id: number;
  public first_name: string;
  public last_name: string;
  public company: string;
  public email: string;
  public password: string;
  public passwordConfirm: string;
  public notes: string;
  public activated: boolean = false;
  public properties: any;
  public associated_properties: string;
  public brandColour: string = '#5f88a2';

  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    public navParams: NavParams,
    public formBuilder: FormBuilder,
    private storage: Storage,
    public alertController: AlertController,
    private wordpressService: WordpressService,
  ) {
    this.client_id = this.navParams.get('client_id');

    if ( this.client_id !== 0 ) {
      this.action = 'Update';
    }

    this.storage.get( 'user_info' )
    .then( ( user_info: any ) => {
      this.token = user_info.token;
      if ( user_info.colour.length > 0 ) {
        this.brandColour = user_info.colour;
      }
      this.getProperties();
    } );

  }

  ngOnInit() {

    if ( 'Create' == this.action ) {
      this.formGroupCreate();
    } else {
      this.formGroupUpdate();
      this.readClient();
    }

  }

  closeModal() {
    this.modalController.dismiss();
  }

  formGroupCreate() {
    this.clientForm = this.formBuilder.group( {
      associated_properties: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      notes: [''],
      activated: [false],
      password: ['', Validators.compose( [
        Validators.required,
        Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
      ] ) ],
      passwordConfirm: ['', Validators.required]
    }, { validator: comparePasswordValidator.isValid } );
  }

  formGroupUpdate() {
    this.clientForm = this.formBuilder.group( {
      notes: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      associated_properties: [''],
      activated: [false],
      password: ['', Validators.compose( [
        Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
      ] ) ],
      passwordConfirm: ['']
    }, { validator: comparePasswordValidator.isValid } );
  }

  createClient(): void {
    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      let data = {
        'associated_properties': this.clientForm.value.associated_properties,
        'first_name': this.clientForm.value.first_name,
        'last_name': this.clientForm.value.last_name,
        'company': this.clientForm.value.company,
        'email': this.clientForm.value.email,
        'password': this.clientForm.value.password,
        'notes': this.clientForm.value.notes,
        'activated': this.clientForm.value.activated,
      };

      this.wordpressService.createClient( user_info.token, data ).then( ( response: any ) => {
        if ( response.success ) {
          this.closeModal();
        } else {
          this.presentToast( response.data.message, false );
        }
      } );

    } );
  }

  readClient(): void {

    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      this.wordpressService.readClient( user_info.token, this.client_id ).then( ( response: any ) => {
        this.first_name = response.data.first_name;
        this.last_name = response.data.last_name;
        this.company = response.data.company;
        this.email = response.data.email;
        this.notes = response.data.notes;
        this.activated = '1' == response.data.activated ? true : false;
        this.associated_properties = response.data.associated_properties;

        if ( ! response.success ) {
          this.presentToast( response.data.message, false );
        }

      } );

    } );
  }

  updateClient(): void {
    
    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      let data = {
        'client_id': this.client_id,
        'associated_properties': this.clientForm.value.associated_properties,
        'first_name': this.clientForm.value.first_name,
        'last_name': this.clientForm.value.last_name,
        'company': this.clientForm.value.company,
        'email': this.clientForm.value.email,
        'password': this.clientForm.value.password,
        'notes': this.clientForm.value.notes,
        'activated': this.clientForm.value.activated,
      };

      this.wordpressService.updateClient( user_info.token, data ).then( ( response: any ) => {
        let success = response.success ? true : false;
        this.presentToast( response.data.message, success );
      } );

    } );

  }

  getProperties(): void {
    this.wordpressService.properties( this.token, null, null, null ).then( ( response: any ) => {
      this.properties = response.data.properties;
    } );
  }

  async presentToast( message: string, success: boolean ) {

    let color = success ? 'success' : 'danger';
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
      cssClass: 'toast-message'
    });
    toast.present();
  }

}
