import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-filter',
  inputs: ['filter', 'keyword', 'label'],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  @Input() filter: any;
  @Input() keyword: string;
  @Input() label: string;

  @Output() selected = new EventEmitter<any>();
  @Output() cleared = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  selectEvent( item: any ) {
    this.selected.emit( [item] );
  }

  inputCleared() {
    this.cleared.emit( true );
  }

}

