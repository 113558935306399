import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { WordpressService } from '../../services/wordpress.service';
import { FunctionsService } from '../../services/functions.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.page.html',
  styleUrls: ['./report.page.scss'],
})
export class ReportPage implements OnInit {

  public token: string;
  public report_id: number;
  public report_ref: string;
  public first_name: string;
  public last_name: string;
  public officer_ref: string;
  public property: string;
  public date: string;
  public incident_type: string;
  public unit: string;
  public description: string;
  public bookmarked: boolean = false;
  public brandColour: string = '#5f88a2';

  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    public navParams: NavParams,
    private storage: Storage,
    private wordpressService: WordpressService,
    private functions: FunctionsService,
  ) {
    this.report_id = this.navParams.get('report_id');

    this.storage.get( 'user_info' )
    .then( ( user_info: any ) => {
      this.token = user_info.token;
      if ( user_info.colour.length > 0 ) {
        this.brandColour = user_info.colour;
      }
      this.readReport();
    } );
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

  readReport(): void {
    this.wordpressService.readReport( this.token, this.report_id ).then( ( response: any ) => {
      this.report_ref = response.data.title;
      this.first_name = response.data.first_name;
      this.last_name = response.data.last_name;
      this.officer_ref = response.data.officer_ref;
      this.property = response.data.property;
      this.date = response.data.date;
      this.incident_type = response.data.incident_type;
      this.unit = response.data.unit;
      this.description = response.data.description;
      this.bookmarked = response.data.bookmarked;

      if ( ! response.success ) {
        this.presentToast( response.data.message, false );
      }

    } );
  }

  bookmark(): void {
    this.wordpressService.bookmarkReport( this.token, this.report_id ).then( ( response: any ) => {
      this.bookmarked = true;
      let success = response.success ? true : false;
      this.presentToast( response.data.message, success );
    } );
  }

  formatDate( date: string ) {
    return this.functions.formatDate( date );
  }

  async presentToast( message: string, success: boolean ) {

    let color = success ? 'success' : 'danger';
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
      cssClass: 'toast-message'
    });
    toast.present();
  }

}
