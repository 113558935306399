import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'manager',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./manager/manager.module').then( m => m.ManagerPageModule)
  },
  {
    path: 'client',
    data: { role: ['client'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'officer',
    data: { role: ['officer'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./officer/officer.module').then( m => m.OfficerPageModule)
  },
  {
    path: 'settings',
    data: { role: ['manager','client','officer'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'properties',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./screens/properties/properties.module').then( m => m.PropertiesPageModule)
  },
  {
    path: 'clients',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./screens/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'officers',
    data: { role: ['manager'] },
    loadChildren: () => import('./screens/officers/officers.module').then( m => m.OfficersPageModule)
  },
  {
    path: 'reports/:guid',
    data: { role: ['manager','client'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./screens/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'reports/:guid/type/:type',
    data: { role: ['manager','client'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./screens/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'property',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal/property/property.module').then( m => m.PropertyPageModule)
  },
  {
    path: 'client',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'officer',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal/officer/officer.module').then( m => m.OfficerPageModule)
  },
  {
    path: 'report',
    data: { role: ['manager','client'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'stats/:guid',
    data: { role: ['manager','client'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./screens/stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'missing',
    data: { role: ['manager'] },
    canActivate: [AuthGuardService],
    loadChildren: () => import('./screens/missing/missing.module').then( m => m.MissingPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
