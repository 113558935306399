import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observer } from 'rxjs';
import { WordpressService } from '../services/wordpress.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authState = new BehaviorSubject( false );

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    private wordpressService: WordpressService,
  ) {  }

  async isAllowed( role: Array<string> ) {
    return new Promise( ( resolve, reject ) => {
      this.storage.get('user_info')
      .then( ( user_info ) => {
        if ( user_info ) {
          this.wordpressService.checkToken( user_info.token )
          .then( ( ( wp_token: any ) => { 
            if ( wp_token.success ) {
              if ( role.indexOf(user_info.role) != -1 ) {
                resolve( true );
              } else {
                this.router.navigate( [ user_info.role ] );
              }
            } else {
              this.authState.next( false );
              resolve( false );
            }
          } ) );
        } else {
          this.authState.next( false );
          resolve( false );
        }
      } );
    } );
  };

  login( user_info ) {
    this.storage.set( 'user_info', user_info )
    .then( ( response ) => {
      this.authState.next( true );
      this.router.navigate( [ user_info.role ] );
    } );
  }

  logout() {
    this.storage.get('user_info')
    .then( ( user_info ) => {
      this.storage.remove( 'user_info' )
      .then( () => {
        this.wordpressService.logout( user_info.token )
        .then( () => {
          console.log('test');
          this.authState.next( false );
          this.router.navigate( ['login'] );
        } );
      } );
    } );
  }

  isAuthenticated( state: boolean ) {
    return this.authState.value;
  }

  async getUserInfo( key: string ) {

    let value: string;

    value = await this.storage.get( 'user_info' )
    .then( ( user_info ) => {
      switch ( key ) {
        case 'id': { 
          return user_info.id;
        }
        case 'email': {
          return user_info.email;
        }
        case 'name': {
          return user_info.name;
        }
        case 'role': {
          return user_info.role;
        }
        case 'token': {
          return user_info.token;
        }
        default: '';
      }

    } );

    return value;

  }

}
