import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { WordpressService } from '../../services/wordpress.service';
import { comparePasswordValidator } from  '../../validators/compare-passwords';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {

  public emailForm: FormGroup;
  public passwordForm: FormGroup;
  public email: string;
  public specialCharacters: "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
  public brandColour: string = '#5f88a2';

  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    public formBuilder: FormBuilder,
    private storage: Storage,
    private wordpressService: WordpressService,
  ) { }

  ngOnInit() {

    this.formGroup();

    this.getUserInfo();

  }
  
  closeModal() {

    this.modalController.dismiss();

  }

  formGroup() {

    this.emailForm = this.formBuilder.group( {
      email: ['', Validators.compose([Validators.required, Validators.email])],
    } );

    this.passwordForm = this.formBuilder.group( {
      password: ['', Validators.compose( [
        Validators.required,
        Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
      ] ) ],
      passwordConfirm: ['', Validators.required]
    }, { validator: comparePasswordValidator.isValid } );

  }

  getUserInfo() {

    this.storage.get( 'user_info' )
    .then( ( user_info ) => {
      this.email = user_info.email;
      if ( user_info.colour.length > 0 ) {
        this.brandColour = user_info.colour;
      }
    } );

  }

  updateEmail() {

    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      const emailValue = this.emailForm.value.email;
      
      this.wordpressService.updateEmail( emailValue, user_info.token ).then( ( response: any ) => {

        if ( response.success ) {

          this.storage.set( 'user_info', { 'id': user_info.id, 'role': user_info.role, 'email': response.data.new_email, 'name': user_info.name, 'token': user_info.token } )
          .then( () => {
            this.presentToast( response.data.message, true );
          } );

        } else {

          this.presentToast( response.data.message, false );

        }

      } );

    } );

  }

  updatePassword() {

    this.storage.get( 'user_info' )
    .then( ( user_info ) => {

      const passwordValue = this.passwordForm.value.password;
      
      this.wordpressService.updatePassword( passwordValue, user_info.token ).then( ( response: any ) => {
        
        let success = response.success ? true : false;
        this.presentToast( response.data.message, success );

      } );

    } );

  }

  async presentToast( message: string, success: boolean ) {

    let color = success ? 'success' : 'danger';
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: color,
      cssClass: 'toast-message'
    });
    toast.present();
  }

}
