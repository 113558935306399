import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { map } from 'rxjs/operator';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  //url = `https://db.security.com/wp-json/mister-app/v1/`;
  //url = `https://db.security.test/wp-json/mister-app/v1/`;
  url = `https://db.propsecured.com/wp-json/mister-app/v1/`;
  api_key = `fe0481740f1d061b64d9`;

  constructor( private http: HttpClient ) { }

  login( email: string, password: string ) {

    return this.http.post( `${this.url}auth`, {
      api_key: this.api_key,
      username: email,
      password: password
    } );

  }

  async checkToken( token: string ) {
    
    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}token`, {
          api_key: this.api_key,
          token: token
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async logout( token: string ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}auth`, {
        api_key: this.api_key,
        logout: true,
        token: token
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async updateEmail( email: string, token: string ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}settings`, {
        api_key: this.api_key,
        token: token,
        action: 'update_email',
        email: email
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async updatePassword( password: string, token: string ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}settings`, {
        api_key: this.api_key,
        token: token,
        action: 'update_password',
        password: password,

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }


  //Properties
  async properties( token: string, posts_per_page: number, paged: number, keyword: string  ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}properties`, {
        api_key: this.api_key,
        token: token,
        posts_per_page: posts_per_page,
        paged: paged,
        keyword: keyword

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async createProperty( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}create_property`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async readProperty( token: string, property_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}read_property`, {
        api_key: this.api_key,
        token: token,
        property_id: property_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async updateProperty( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}update_property`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async deleteProperty( token: string, property_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}delete_property`, {
        api_key: this.api_key,
        token: token,
        property_id: property_id,

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  //Clients
  async clients( token: string, number: number, paged: number, keyword: string  ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}clients`, {
        api_key: this.api_key,
        token: token,
        number: number,
        paged: paged,
        keyword: keyword

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async createClient( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}create_client`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async readClient( token: string, client_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}read_client`, {
        api_key: this.api_key,
        token: token,
        client_id: client_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async updateClient( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}update_client`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async deleteClient( token: string, client_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}delete_client`, {
        api_key: this.api_key,
        token: token,
        client_id: client_id,

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  //Officers
  async officers( token: string, number: number, paged: number, keyword: string  ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}officers`, {
        api_key: this.api_key,
        token: token,
        number: number,
        paged: paged,
        keyword: keyword

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async createOfficer( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}create_officer`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async readOfficer( token: string, officer_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}read_officer`, {
        api_key: this.api_key,
        token: token,
        officer_id: officer_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async updateOfficer( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}update_officer`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async deleteOfficer( token: string, officer_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}delete_officer`, {
        api_key: this.api_key,
        token: token,
        officer_id: officer_id,

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }


  //Reports
  async incidentTypes( token: string ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}incident_types`, {
        api_key: this.api_key,
        token: token,

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async createReport( token: string, data: any ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}create_report`, {
        api_key: this.api_key,
        token: token,
        data: data,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async readReport( token: string, report_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}read_report`, {
        api_key: this.api_key,
        token: token,
        report_id: report_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async reports( token: string, posts_per_page: number, paged: number, property_id: number, keyword: string, filter_bookmarks: boolean, type: string ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}reports`, {
        api_key: this.api_key,
        token: token,
        posts_per_page: posts_per_page,
        paged: paged,
        property_id: property_id,
        keyword: keyword,
        filter_bookmarks: filter_bookmarks,
        type: type

      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async reportStats( token: string, property_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}report_stats`, {
        api_key: this.api_key,
        token: token,
        property_id: property_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async bookmarkReport( token: string, report_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}bookmark_report`, {
        api_key: this.api_key,
        token: token,
        report_id: report_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async unbookmarkReport( token: string, report_id: number ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}unbookmark_report`, {
        api_key: this.api_key,
        token: token,
        report_id: report_id,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

  async missingReports( token: string ) {

    return new Promise( ( resolve, reject ) => {

      this.http.post( `${this.url}missing_reports`, {
        api_key: this.api_key,
        token: token,
      } ).subscribe( response => {
        resolve( response );
      } );

    } );

  }

}
