import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise( ( resolve, reject ) => {
      this.authenticationService.isAllowed( next.data['role'] ).then( ( canDo: boolean ) => {
        if ( canDo ) {
          resolve( true );
        } else {
          this.router.navigate( ['login'] );
          resolve( false );
        }
      } );
    } );
  }
}