import { AbstractControl } from '@angular/forms';

export class comparePasswordValidator {

    static isValid( control: AbstractControl ): any {

        if ( control.get('password').value === control.get('passwordConfirm').value ) {

            return null;

        } else {

            return {
                "Password do not match": true
            };

        }

    }

}