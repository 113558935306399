import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor() { }

  dayDifference( date: string ) {

    const dateTimeNow = new Date().getTime();
    const dateCompareTime = new Date( date ).getTime();
    const day = 60 * 60 * 24 * 1000;
    
    return Math.floor( ( dateCompareTime - dateTimeNow ) / day );

  }

  formatDate( date: string = '' ) {

    if ( date.length > 0 ) {

      const options = { year: 'numeric', month: '2-digit', day: 'numeric' };
      const strToDate = new Date( date );
      return strToDate.toLocaleDateString( "en-GB", options );

    } else {

      return '';

    }

  }

  formatDateTime( date: string = '' ) {

    if ( date.length > 0 ) {

      const options = { year: 'numeric', month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      const strToDate = new Date( date );
      return strToDate.toLocaleDateString( "en-GB", options );
    
    } else {

      return '';

    }

  }

}